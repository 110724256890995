import React from 'react';
import styled, { css } from 'styled-components';
import { Nav } from '@catalogo/core-nav';
import { getIn } from 'seamless-immutable';
import Button from '@catalogo/ui-americanas-desktop-button';
import Arrow from '@catalogo/ui-americanas-desktop-svg/arrow.svg';

const ZionNavigationTabsList = ({ publication, renderComponent, history, numberItemsLimit = 6 }) => {
  const { children, navigationTabsListTitle, isFull } = publication;
  const openModal = () => {
    const pathname = getIn(history, ['location', 'pathname'], '');
    const search = getIn(history, ['location', 'search'], '');

    return history.push(`${pathname}${search}#modal-navigation-tabs-show-more`, { publication, renderComponent });
  };

  const itemsLimit = isFull ? children.length : numberItemsLimit;

  const isShowButtonMore = () => !isFull && children && children.length > numberItemsLimit;
  return (
    <NavigationTabsList>
      <ListHeader>{navigationTabsListTitle}</ListHeader>
      <ListLinkContainer>
        {children
          .map(child => (
            <Link to={child.linkUrl} key={child._id}>
              {renderComponent({ showTitle: true, ...child })}
            </Link>
          ))
          .slice(0, itemsLimit)}
      </ListLinkContainer>
      {isShowButtonMore() ? (
        <ButtonSeeMore onClick={openModal}>
          <ButtonText title="Ver mais tipos de móveis" />
          <ArrowSvg />
        </ButtonSeeMore>
      ) : null}
    </NavigationTabsList>
  );
};

export default ZionNavigationTabsList;

const NavigationTabsList = styled.div`
  background-color: ${({ theme }) => theme.white};
`;

const ListHeader = styled.p`
  text-transform: ${({ theme }) => theme.titleTransform};
  color: ${({ theme }) => theme.titleColor};
  font-size: 16px;
  font-weight: bold;
  padding: 0 15px 20px;
`;

const ListLinkContainer = styled.div`
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 15px;
  row-gap: 25px;
  width: 100%;
  padding: 0 15px 25px;
`;

const Link = styled(Nav)`
  font-size: ${({ theme }) => theme.fontSize.xxs};
  color: ${({ theme }) => theme.titleColor};
  text-decoration: none;
  cursor: pointer;
  transition: 0.3s;
  margin: 0;
  padding: 0;
  :hover {
    text-decoration: underline;
  }
`;

const ButtonSeeMore = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #efefef;
  cursor: pointer;
  padding: 20px 15px;
`;

const ButtonText = styled(Button)`
  ${({ theme }) => css`
    color: ${theme.buttonPrimaryColor};
    font-weight: ${theme.fontWeight};
  `}
  justify-content: space-between;
  font-size: 14px;
  background-color: #efefef;
`;

const ArrowSvg = styled(Arrow)`
  fill: ${({ theme }) => theme.primaryColor};
  transform: rotate(270deg);
  width: 15px;
  height: auto;
`;
