import React from 'react';
import styled from 'styled-components';
import { Accordion } from '@catalogo/theme-accordion';

const ZionCollapse = ({ publication }) => {
  const { children, componentTitle } = publication;
  const test = children.map(child => ({
    title: child.componentTitle,
    content: [{ title: child.componentText }],
  }));

  return (
    <>
      <ZionCollapseTitle className="text">{componentTitle}</ZionCollapseTitle>
      {test.map(child => (
        <AccordionContainer title={child.title} content={child.content} key={child._id} />
      ))}
    </>
  );
};

export default ZionCollapse;

const ZionCollapseTitle = styled.p`
  text-transform: ${({ theme }) => theme.titleTransform};
  font-size: 26px;
  font-weight: bold;
  color: ${({ theme }) => theme.titleColor};
  background-color: transparent;
  border: none;
  padding: 12px;
  -webkit-transition: all ease-in-out 0.2s;
  -o-transition: all ease-in-out 0.2s;
  transition: all ease-in-out 0.2s;
`;

const AccordionContainer = styled(Accordion)`
  margin-bottom: 0.7rem;
  :last-child {
    margin-bottom: 30px;
  }
`;
